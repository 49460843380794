<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">
        {{ $t("DefaultLanguage") }}
      </p>
    </div>

    <v-card class="dialogmargin">
      <div class="ml-2 mt-3 pa-4">
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <p style="font-size: 15px" class="ma-0 ml-1 mb-2">
              {{ $t("SearchCriteria") }}
            </p>
            <v-autocomplete
              v-model="searchcriteria"
              :items="myCriterialist"
              :item-text="(item) => `${item.txt}`"
              item-value="val"
              color="#F99D20"
              clearable
              outlined
              dense>
              <v-icon
                slot="append"
                class="v-icon notranslate mdi mdi-chevron-down theme--light"
              />
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-text-field
              autocomplete="off"
              :placeholder="$t('Search')"
              class="mt-7 ml-3 lock"
              v-model.lazy="searchtxt"
              outlined
              color="#F99D20"
              dense
              style="margin-top: -0.8rem; margin-left: -1rem"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              color="#F99D20"
              @click="Search"
              class="white--text text-capitalize ml-5 mt-7 custombtn"
              width="120"
              max-height="35">
              <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
            </v-btn>
          </v-col>
        </v-row>
        
        <v-data-table
          :headers="headers"
          :items="LanguageList"
          class="ml-4 mr-4"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          hide-default-header
          :custom-filter="filterOnlyCapsText">
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th
                  style="cursor: pointer; min-width: 300px !important"
                  class="b-text"
                >
                  {{ $t("companyName") }}
                </th>
                <th
                  style="cursor: pointer; min-width: 300px !important"
                  class="b-text"
                >
                  {{ $t("DefaultLanguagefor") }}
                </th>

                <th>{{ $t("action") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.company_Name }}</td>
              <td>{{ Checkingtranslation(item.language_Name) }}</td>
              <td>
                <v-btn icon router @click="EditLanguage(item)">
                  <img class="editIcon" :src="images.edit" alt=""/>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div>
          <CustomPaginationComponent
            :total-items="totalItems"
            :page.sync="page"
            :itemsPerPage.sync="itemsPerPage"
            @update:page="onPageChanged"
            @update:itemsPerPage="onItemsPerPageChanged"
            :showPageText="false"/>
        </div>
      </div>
    </v-card>
    
    <v-dialog v-model="Editdialog" persistent max-width="450px">
      <v-card style="border-radius: 15px">
        <p
          class="pt-5 ml-5 mr-2"
          style="font-size: 22px; font-family: 'Kanit', sans-serif">
          {{ $t("EditDefaultLanguage") }}
        </p>
        <v-card-text>
          <v-container style="margin-top: -1.3rem">
            <v-form
              ref="formAdd"
              lazy-validation
              style="margin-left: -1rem; margin-right: -1rem">
              <v-col cols="12" sm="6" md="12">
                <p class="pa-0 ma-0">{{ $t("companyName") }}</p>
                <v-text-field
                  v-model="edit.company_Name"
                  readonly
                  autocomplete="off"
                  color="#F99D20">
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="12">
                <p class="pa-0 ma-0">{{ $t("DefaultLanguagefor") }}</p>
                <v-autocomplete
                  class="mt-3"
                  v-model="edit.language_Name"
                  :items="mylanglist"
                  :item-text="(item) => `${item.txt}`"
                  item-value="val"
                  dense
                  color="#F99D20">
                </v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 2rem">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              color="darken-1"
              class="mb-4 mr-2 text-capitalize"
              width="100"
              max-height="35"
              text
              @click="Editdialog = false" >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="#F99D20"
              class="mb-5 text-capitalize"
              width="120"
              max-height="35"
              @click="UpdateDefaultLangugageSetting">
              {{ $t("Update") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
// import store from "../store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/Function.js";
import CustomPaginationComponent from '../components/CustomPagination.vue';
import LoadingDialog from "@/components/Loading.vue";
export default {
  mixins: [LogTrace],
  components: { CustomPaginationComponent, LoadingDialog },
  data() {
    return {
      searchlength: 0,
      LoadingDialog: false,
      url: enurl.apiUrl,
      Editdialog: false,
      dialog: false,
      page: 1,
      itemsPerPage: 10,
      images: {
        edit: require("@/assets/images/editIcon.svg"),
      },
      headers: [
        {
          text: "Company Name",
          value: "company_Name",
          align: "left",
          sortable: true,
        },
        {
          text: "Default Language",
          value: "language_Name",
          align: "left",
          sortable: true,
        },
      ],
      Criteria: ["Company Name", "Language"],
      searchcriteria: "",
      searchtxt: "",
      LanguageList: [],
      LangList: [
        { id: 1, language_Name: "English" },
        { id: 2, language_Name: "Thai" },
        { id: 3, language_Name: "Vietnam" },
        { id: 4, language_Name: "Chinese" },
        { id: 5, language_Name: "Myanmar" },
      ],
      edit: {
        language_ID: null,
        company_Name: null,
        language_Name: null,
      },
      totalItems: 0,
    };
  },

  mounted() {
    this.GetDefaultLanguageSetting();
  },
  
  computed: {
    mylanglist() {
      return [
        { val: "English", txt: this.$t("EnglishLanguage") },
        { val: "Thai", txt: this.$t("Thai") },
        { val: "Myanmar", txt: this.$t("Myanmar") },
        { val: "Vietnam", txt: this.$t("Vietnam") },
        { val: "Chinese", txt: this.$t("ChineseLanguage") },
      ];
    },
    myCriterialist() {
      return [
        { val: "Company Name", txt: this.$t("companyName") },
        { val: "Language", txt: this.$t("language") },
      ];
    },

    Checkingtranslation() {
      return function (value) {
        return value == "English"
          ? this.$t("EnglishLanguage")
          : value == "Thai"
          ? this.$t("Thai")
          : value == "Myanmar"
          ? this.$t("Myanmar")
          : value == "Vietnam"
          ? this.$t("Vietnam")
          : this.$t("ChineseLanguage");
      };
    },
  },
  watch: {
    searchtxt: function () {
      if (this.searchlength == 1)
      {
        this.searchlength = 0;
        this.searchtxt = "";
        this.GetDefaultLanguageSetting();
      }
    },
  },
  methods: {
    onPageChanged(newPage) {
      this.page = newPage;
      this.GetDefaultLanguageSetting();
    },

    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.GetDefaultLanguageSetting();
    },

    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined)
      {
        self.searchlength = 1;
        self.page = 1;
        if (this.searchcriteria == "Company Name")
        {
          self.GetDefaultLanguageSetting();
        }
        else if (this.searchcriteria == "Language")
        {
          self.GetDefaultLanguageSetting();
        }
        else
        {
          alert("Select Search Criteria");
        }
      }
      else
      {
        alert("Please enter text");
      }
    },

    EditLanguage(temp) {
      let self = this;
      self.Editdialog = true;
      self.edit.language_ID = temp.language_ID;
      self.edit.company_Name = temp.company_Name;
      self.edit.language_Name = temp.language_Name;
    },

    filterOnlyCapsText(value, search, item) {
      if (this.searchcriteria == "Company Name")
      {
        let inName = RegExp(search, "i").test(item.company_Name);
        let inTags = false;
        return inName | inTags;
      }
      if (this.searchcriteria == "Language")
      {
        let inName = RegExp(search, "i").test(item.language_Name);
        let inTags = false;
        return inName | inTags;
      }
    },

    GetDefaultLanguageSetting() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let ctxt = "";
        let ltxt = "";
        if (self.searchcriteria == "Company Name")
        {
          ctxt = self.searchtxt;
        }
        else
        {
          ltxt = self.searchtxt;
        }
        //self.searchlength = 0;
        axios.get(`${self.url}DefaultLanguageSetting/GetAllDefaultLanguageSettingsPagination?perPage=${self.itemsPerPage}&page=${self.page}&companyName=${ctxt}&language=${ltxt}`)
        .then(function (response) {
          self.LoadingDialog = false;
          if(response.data.status == 0 && response.data.data != null)
          {
            self.LanguageList = response.data.data.result;
            self.totalItems =  response.data.data.totalCount;
          }
          else
          {
            self.LanguageList = [];
            self.totalItems = 0;
            alert(response.data.message)
          }
        });
      } 
      catch (error) {
        alert(error);
        self.LogTrace(error,"Get Default Language Setting Fail",47,"Critical");
      }
    },

    UpdateDefaultLangugageSetting() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        language_ID: self.edit.language_ID,
        language_Name: self.edit.language_Name,
      };
      axios.post(`${self.url}DefaultLanguageSetting/UpdateDefaultLanguageSetting`,temp)
      .then(function (response) {
        self.LoadingDialog = false;
        alert(response.data.message);
        if (response.data.status == 0)
        {
          self.Editdialog = false;
          self.GetDefaultLanguageSetting();
        }
      })
      .catch(function (error) {
        alert(error);
        self.LogTrace(error,"Update Default Language Setting Fail",47,"Medium");
        self.LoadingDialog = false;
      });
    },
  },
};
</script>

<style scoped>
@media (min-width: 280px) and (max-width: 541px) {
  .lock {
    margin-top: -2rem !important;
    margin-left: -0.1rem !important;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .custombtn {
    margin-top: -2rem !important;
    margin-left: -0.1rem !important;
  }
}

::v-deep .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
::v-deep .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
::v-deep .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

::v-deep .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
::v-deep .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
::v-deep .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
::v-deep .v-data-table tr td {
  height: 70px;
}
::v-deep .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
::v-deep .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  align-content: center;
}
</style>