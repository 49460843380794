<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div style="background-color: #f0f0f7">
      <p style="margin-left: 4px; margin-top: 22px" class="headertext">
        {{ $t("UsageReportByType") }}
      </p>
    </div>
    <v-card class="dialogmargin card pb-4" style="margin-bottom: 5rem">
      <v-layout row="wrap" align-center class="mx-5 pt-8 pb-0">
        <p>{{ $t("CompanyStatus") }}</p>
      </v-layout>
      <v-layout row="wrap" align-center class="mx-5 pt-0">
        <v-flex shrink my-5 class="switch-btn">
          <v-btn block tile outlined class="text" @click="onClickActive" :style="{
            'border-radius': '5px 0 0 5px',
            height: '40px',
            'text-transform': 'none',
            'background-color': running.backcolor,
            color: running.color,
            border: running.border,
          }">{{ $t("active") }}</v-btn>
        </v-flex>
        <v-flex shrink my-5 class="switch-btn">
          <v-btn block class="text" depressed tile @click="onClickInactive" :style="{
            'border-radius': '0 5px 5px 0',
            height: '40px',
            'text-transform': 'none',
            'background-color': removed.backcolor,
            color: removed.color,
            border: removed.border,
          }">{{ $t("inactive") }}</v-btn>
        </v-flex>
      </v-layout>
      <v-row>
        <v-col cols="7" sm="6" md="6" lg="3">
          <!-- <v-row> -->
          <v-text-field color="#F99D20" v-model="search" dense outlined style="
                  margin-top: -0.1rem;
                  margin-left: 20px;
                  min-width: 100px;
                  max-width: 350px;
                  border-radius: 5px;
                " :placeholder="$t('searchByCompanyName')"></v-text-field>
        </v-col>
        <v-col cols="3" sm="6" md="6" lg="2">
          <v-btn color="#F99D20" @click="Search()" class="white--text text-capitalize mb-2" width="120"
            max-height="35">
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="2" xl="1" class="pb-0">
          <p class="mt-2 ml-5 mb-0">{{ $t("DateofUse") }} :</p>
        </v-col>
        <v-col cols="5" sm="6" md="4" lg="2">
          <v-row class="ml-1">
            <p class="mt-2 ml-5">{{ $t("from") }}</p>
            <date-picker v-bind:clearable="false" v-model="reportDateFrom" format="DD/MM/YYYY" :disabled="disablepicker"
              :disabled-date="(date) =>
                  date > new Date() ||
                  date > new Date(reportDateTo)
                " @change="GetReportList(1)" placeholder="DD/MM/YYYY" class="fromdate custom-vuedatepicker2" style="
              margin-left: 15px;
              margin-top: 0.2rem;
              width: 150px;
              border-radius: 5px;
            ">
            </date-picker>
          </v-row>
        </v-col>

        <v-col cols="5" sm="6" md="4" lg="2">
          <v-row class="ml-1">
            <p class="mt-2 ml-5">{{ $t("to") }}</p>
            <date-picker v-bind:clearable="false" v-model="reportDateTo" class="custom-vuedatepicker2" format="DD/MM/YYYY" width="100%" :disabled-date="(date) =>
                date < BeforeFromDate() ||
                date > new Date()
              " @change="GetReportList(1)" placeholder="DD/MM/YYYY" style="
              margin-left: 15px;
              margin-top: 0.2rem;
              width: 150px;
              border-radius: 5px;
            ">
            </date-picker>
          </v-row>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="2" sm="4" xs="12">
          <v-btn @click="Export()" color="#2E7D32" outlined class="d-flex justify-end ml-6" style="width: 180px">
            <img width="20" height="20" :src="images.excel" alt class="mr-3" />
            {{ $t("exportExcel") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table style="margin-left: 13px; margin-right: 13px" class="mytable" :headers="headers" :items="ReportList"
        :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
        header-props.sort-icon="mdi-menu-down" hide-default-footer hide-default-header>
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('company_Name')">
                {{ headers[0].text }}
                <v-icon small v-if="!company_Name">mdi-arrow-down</v-icon>
                <v-icon small v-if="company_Name">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('register_Date')">
                {{ headers[1].text }}
                <v-icon small v-if="!register_Date">mdi-arrow-down</v-icon>
                <v-icon small v-if="register_Date">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('expired_date')">
                {{ headers[2].text }}
                <v-icon small v-if="!expired_date">mdi-arrow-down</v-icon>
                <v-icon small v-if="expired_date">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('total_Staff')">
                {{ headers[3].text }}
                <v-icon small v-if="!total_Staff">mdi-arrow-down</v-icon>
                <v-icon small v-if="total_Staff">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('active_Staff')">
                {{ headers[4].text }}
                <v-icon small v-if="!active_Staff">mdi-arrow-down</v-icon>
                <v-icon small v-if="active_Staff">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('last_Login')">
                {{ headers[5].text }}
                <v-icon small v-if="!last_Login">mdi-arrow-down</v-icon>
                <v-icon small v-if="last_Login">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('login_Web')">
                {{ headers[6].text }}
                <v-icon small v-if="!login_Web">mdi-arrow-down</v-icon>
                <v-icon small v-if="login_Web">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('added_Employee')">
                {{ headers[7].text }}
                <v-icon small v-if="!added_Employee">mdi-arrow-down</v-icon>
                <v-icon small v-if="added_Employee">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 200px !important; color: #f99d20" class="b-text"
                @click="sortFun('post_Announcement')">
                {{ headers[8].text }}
                <v-icon small v-if="!post_Announcement">mdi-arrow-down</v-icon>
                <v-icon small v-if="post_Announcement">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('clock_In')">
                {{ headers[9].text }}
                <v-icon small v-if="!clock_In">mdi-arrow-down</v-icon>
                <v-icon small v-if="clock_In">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('clock_Out')">
                {{ headers[10].text }}
                <v-icon small v-if="!clock_Out">mdi-arrow-down</v-icon>
                <v-icon small v-if="clock_Out">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text" @click="sortFun('request_OT')">
                {{ headers[11].text }}
                <v-icon small v-if="!request_OT">mdi-arrow-down</v-icon>
                <v-icon small v-if="request_OT">mdi-arrow-up</v-icon>
              </th>
              <th style="cursor: pointer; min-width: 180px !important; color: #f99d20" class="b-text"
                @click="sortFun('request_Time_Off')">
                {{ headers[12].text }}
                <v-icon small v-if="!request_Time_Off">mdi-arrow-down</v-icon>
                <v-icon small v-if="request_Time_Off">mdi-arrow-up</v-icon>
              </th>
            </tr>
          </thead>
          <thead>
            <tr style="background: #F0F8FF;">
              <th style="cursor: pointer; min-width: 180px !important" class="b-text">
                {{ $t("Total") }}
              </th>
              <th></th>
              <th></th>
              <th>{{ totalvalue.all_Staff }}</th>
              <th>{{ totalvalue.all_Active_Staff }}</th>
              <th></th>
              <th>{{ totalvalue.all_login }}</th>
              <th>{{ totalvalue.all_addEmp }}</th>
              <th>{{ totalvalue.all_announcement }}</th>
              <th>{{ totalvalue.all_In }}</th>
              <th>{{ totalvalue.all_Out }}</th>
              <th>{{ totalvalue.all_OT }}</th>
              <th>{{ totalvalue.all_TO }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td style="background: #F0F8FF;">{{ item.company_Name }}</td>
            <td style="background: #F0F8FF;">{{ datestyle(item.register_Date) }}</td>
            <td style="background: #F0F8FF;">{{ datestyle(item.expired_Date) }}</td>
            <td style="background: #F0F8FF;">{{ item.total_Staff }}</td>
            <td style="background: #F0F8FF;">{{ item.active_Staff }}</td>
            <td>{{ datestyle(item.last_Login) }}</td>
            <td>{{ item.login_Web }}</td>
            <td>{{ item.added_Employee }}</td>
            <td>{{ item.post_Announcement }}</td>
            <td>{{ item.clock_In }}</td>
            <td>{{ item.clock_Out }}</td>
            <td>{{ item.request_OT }}</td>
            <td>{{ item.request_Time_Off }}</td>
          </tr>
        </template>
      </v-data-table>
      <v-spacer></v-spacer>
      <!-- <div class="text-right">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage" :items="items"
              @input="itemsPerPage = parseInt($event, 10)"></v-select>
          </v-col>
        </v-row>
      </div> -->
      <div>
        <CustomPaginationComponent
          :total-items="totalItems"
          :page.sync="page"
          :itemsPerPage.sync="itemsPerPage"
          @update:page="onPageChanged"
          @update:itemsPerPage="onItemsPerPageChanged"
          :showPageText="false"/>
      </div>
    </v-card>
    
    <v-dialog v-model="exportdialog" persistent width="400">
      <v-card class="mydialog">
        <v-card-title style="text-align: center">
          <p style="margin-top: 4px; margin-bottom: -30px; margin-left: 10px">
            {{ $t("export") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="12" class="mt-3">
            {{ $t("fileType") }}
            <v-text-field readonly v-model="filetype" color="#F99D20" dense class="mr-1"></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            {{ $t("start_date") }}
            <v-menu v-model="effstartdate" :close-on-content-click="false" transition="scale-transition" offset-y fluid
              max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="computedDateFormatted1" class="kanit-medium" color="#F99D20" persistent-hint
                  append-icon="mdi-calendar-month-outline" v-on="on">
                  <template v-slot:append>
                    <img class="mr-3" style="margin-top: 3px" width="18px" height="18px"
                      src="@/assets/images/Mask Group 160@2x.png" />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="startdate" no-title @input="effstartdate = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="12">
            {{ $t("enddata") }}
            <v-menu v-model="effendate" :close-on-content-click="false" transition="scale-transition" offset-y fluid
              max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="computedDateFormatted7" class="kanit-medium" color="#F99D20" persistent-hint
                  append-icon="mdi-calendar-month-outline" v-on="on">
                  <template v-slot:append>
                    <img class="mr-3" style="margin-top: 3px" width="18px" height="18px"
                      src="@/assets/images/Mask Group 160@2x.png" />
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="enddate" no-title @input="effendate = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-card-text>
        <v-card-actions class="mr-5" style="margin-top: -1rem">
          <v-spacer></v-spacer>
          <v-btn class="mr-2 text-capitalize" width="120" height="40" @click="CloseDialog()" text>{{ $t("cancel")
          }}</v-btn>
          <v-btn color="#F99D20" class="mr-4 text-capitalize export" width="120" height="40" @click="Export()">{{
            $t("export") }}</v-btn>
        </v-card-actions>
        <br />
      </v-card>
    </v-dialog>

    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import excel from "vue-excel-export";
import LogTrace from "@/api/Function.js";
import XLSX from "xlsx";
import CustomPaginationComponent from '../components/CustomPagination.vue';
import LoadingDialog from "@/components/Loading.vue";
Vue.use(excel);
export default {
  mixins: [LogTrace],
  components: { DatePicker, CustomPaginationComponent, LoadingDialog },
  data() {
    return {
      url: enurl.apiUrl,
      active: [],
      inactive: [],
      filetype: "Excel (.xlsx)",
      effendate: false,
      effstartdate: false,
      enddate: "",
      startdate: "",
      exportdialog: false,
      detailDialog: false,
      totalvalue: {
        all_Staff: 0,
        all_Active_Staff: 0,
        all_login: 0,
        all_announcement: 0,
        all_In: 0,
        all_Out: 0,
        all_OT: 0,
        all_TO: 0,
        all_addEmp: 0,
      },
      startDate: null,
      endDate: null,
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      logdataAll: [],
      ddlValue: 1,
      customDDL: "",
      reportDateFrom: new Date(),
      reportDateTo: new Date(),
      images: {
        excel: require("@/assets/images/exccelicon@2x.png"),
      },
      removed: {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      },
      current: "active",
      running: {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      },
      disablepicker: false,
      AItabs: true,
      trfl: true,
      loadingdialog: false,
      ReportList: [],
      replength: 0,
      LogData: [],
      tmpLog: [],
      tmpLogg: [],
      page: 1,
      itemsPerPage: 10,
      search: "",
      totalCount: 0,
      totalItems: 0,
      ReportListExport: [],
      ExportLog: null,
      startyear: null,
      endyear: null,
      sheetTitle: null,
      company_Name: null,
      total_Staff: null,
      active_Staff: null,
      login_Web: null,
      post_Announcement: null,
      added_Employee: null,
      clock_In: null,
      clock_Out: null,
      request_OT: null,
      request_Time_Off: null,
      disforfuture: false,
      expired_date: null,
      register_Date: null,
      last_Login: null,
      searchlength: 0,
    };
  },
  computed: {
    selectDDL() {
      return [
        {
          value: 1,
          text: this.$t("Today"),
        },
        {
          value: 2,
          text: this.$t("Yesterday"),
        },
      ]
    },
    computedDateFormatted1() {
      return this.formatDate(this.startdate);
    },
    computedDateFormatted7() {
      return this.formatDate(this.enddate);
    },
    headers() {
      return [
        {
          text: this.$t("companyName"),
          value: "company_Name",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("registerDate"),
          value: "register_Date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("expiry_Date"),
          value: "expired_date",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("TotalStaff"),
          value: "total_Staff",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("ActiveStaff"),
          value: "active_Staff",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("last_Login"),
          value: "last_Login",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("LoginWeb"),
          value: "login_Web",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("added_Employee"),
          value: "added_Employee",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("PostAnnouncement"),
          value: "post_Announcement",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("ClockIn"),
          value: "clock_In",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("ClockOut"),
          value: "clock_Out",
          align: "left",
          sortable: true,
        },

        {
          text: this.$t("RequestOT"),
          value: "request_OT",
          align: "left",
          sortable: true,
        },
        {
          text: this.$t("RequestTimeOff"),
          value: "request_Time_Off",
          align: "left",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    search: function () {
      if (this.searchlength == 1)
      {
        this.searchlength = 0;
        this.search = "";
        this.GetReportList(1);
      }
    },
  },

  mounted() {
    let self = this;
    self.GetReportList();
  },

  methods: {
    onPageChanged(newPage) {
      this.page = newPage;
      this.GetReportList();
    },

    onItemsPerPageChanged(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.page = 1;
      this.GetReportList();
    },
    BeforeFromDate() {
      let today = new Date();
      let val = new Date(this.reportDateFrom);
      let fromdate = this.reportDateFrom.getDate();
      if (fromdate == today.getDate())
      {
        let d = new Date(val.setDate(val.getDate() - 1));
        return d;
      }
      else 
      {
        let d = new Date(val.setDate(val.getDate() - 1));
        return d;
      }
    },

    onclickExportDialog() {
      let self = this;
      self.exportdialog = true;
    },

    CloseDialog() {
      let self = this;
      self.exportdialog = false;
      self.startdate = "";
      self.enddate = "";
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    onClickInactive() {
      let self = this;
      self.reportDateFrom = new Date();
      self.reportDateTo = new Date();
      self.disablepicker = false;
      self.customDDL = "";
      self.search = "";
      self.AItabs = false;
      self.removed = {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      };
      self.running = {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      };
      self.GetReportList();
    },

    onClickActive() {
      let self = this;
      self.reportDateFrom = new Date();
      self.reportDateTo = new Date();
      self.customDDL = "";
      self.search = "";
      self.disablepicker = false;
      self.AItabs = true;
      self.running = {
        color: "#fff",
        backcolor: "#F99D20",
        border: "1px solid #F99D20",
      };
      self.removed = {
        color: "#F99D20",
        backcolor: "#fff",
        border: "1px solid #F99D20",
      };
      self.GetReportList();
    },

    plusDay() {
      let self = this;
      if (self.reportDateFrom != null) 
      {
        const date = new Date(
          self.reportDateFrom.getFullYear(),
          self.reportDateFrom.getMonth(),
          self.reportDateFrom.getDate() + 1
        );
        self.reportDateFrom = date;
        self.replength = 0;
        self.GetReportList();
      }
    },

    minusDay() {
      let self = this;
      if (self.reportDateFrom != null) 
      {
        const date = new Date(
          self.reportDateFrom.getFullYear(),
          self.reportDateFrom.getMonth(),
          self.reportDateFrom.getDate() - 1
        );
        self.reportDateFrom = date;
        self.replength = 0;
        self.GetReportList();
      }
    },

    async GetReportList(v) {
      try {
        let self = this;
        if (v == 1 || self.replength == 0 || self.replength != self.ReportList.length || self.trfl != self.AItabs) 
        {
          let tof = self.AItabs;
          self.trfl = tof;
          self.ReportList = [];
          self.startyear = null;
          self.endyear = null;
          self.ReportListExport = [];
          self.totalvalue.all_Staff = 0;
          self.totalvalue.all_Active_Staff = 0;
          self.totalvalue.all_login = 0;
          self.totalvalue.all_announcement = 0;
          self.totalvalue.all_In = 0;
          self.totalvalue.all_Out = 0;
          self.totalvalue.all_OT = 0;
          self.totalvalue.all_TO = 0;
          self.totalvalue.all_addEmp = 0;
          if (self.reportDateFrom != null) 
          {
            const condate = new Date();
            if (
              self.reportDateFrom.getDate() == condate.getDate() &&
              self.reportDateFrom.getMonth() == condate.getMonth() &&
              self.reportDateFrom.getFullYear() == condate.getFullYear())
            {
              self.disforfuture = true;
            }
            else 
            {
              self.disforfuture = false;
            }
            let month = String(self.reportDateFrom.getMonth() + 1);
            let day = String(self.reportDateFrom.getDate());
            const year = String(self.reportDateFrom.getFullYear());
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            self.startyear = `${day}/${month}/${year}`;
            let emonth = String(self.reportDateTo.getMonth() + 1);
            let eday = String(self.reportDateTo.getDate());
            const eyear = String(self.reportDateTo.getFullYear());
            if (emonth.length < 2) emonth = "0" + emonth;
            if (eday.length < 2) eday = "0" + eday;
            self.endyear = `${eday}/${emonth}/${eyear}`;
          }
          let url = `${self.url}FeatureReport/GetReportByType?status=${tof}&page=${self.page}&perpage=${self.itemsPerPage}`;
          if (self.startyear != null) 
          {
            url += `&startDate=${self.startyear}`;
          }
          if (self.endyear != null) 
          {
            url += `&endDate=${self.endyear}`;
          }
          if (self.search != "") 
          {
            url += `&companyName=${self.search}`;
          }
          self.loadingdialog = true;
          await axios.get(url).then(function (response) {
            if (response.data.data.totalCount == 0 || response.data.data.totalCount == null) 
            {
              self.ReportList = [];
            }
            self.ReportList = response.data.data.result;
            self.totalItems = response.data.data.totalCount;
            self.replength = self.totalItems;
            if (self.ReportList[0]) 
            {
              self.tmpLog = response.data.data;
              self.totalvalue.all_Staff = self.ReportList[0].all_Staff;
              self.totalvalue.all_Active_Staff = self.ReportList[0].all_Active_Staff;

              for (let j = 0; j < self.ReportList.length; j++) 
              {
                self.totalvalue.all_login = self.totalvalue.all_login + parseInt(self.ReportList[j].login_Web);
                self.totalvalue.all_addEmp = self.totalvalue.all_addEmp + parseInt(self.ReportList[j].added_Employee);
                self.totalvalue.all_announcement = self.totalvalue.all_announcement + parseInt(self.ReportList[j].post_Announcement);
                self.totalvalue.all_In = self.totalvalue.all_In + parseInt(self.ReportList[j].clock_In);
                self.totalvalue.all_Out = self.totalvalue.all_Out + parseInt(self.ReportList[j].clock_Out);
                self.totalvalue.all_OT = self.totalvalue.all_OT + parseInt(self.ReportList[j].request_OT);
                self.totalvalue.all_TO = self.totalvalue.all_TO + parseInt(self.ReportList[j].request_Time_Off);
              }
              // self.ReportListExport.push({
              //   company_Name: "Total",
              //   register_Date: " ",
              //   expired_Date: " ",
              //   total_Staff: self.totalvalue.all_Staff,
              //   active_Staff: self.totalvalue.all_Active_Staff,
              //   last_Login: " ",
              //   login_Web: self.totalvalue.all_login,
              //   added_Employee: self.totalvalue.added_Employee,
              //   post_Announcement: self.totalvalue.all_announcement,
              //   clock_In: self.totalvalue.all_In,
              //   clock_Out: self.totalvalue.all_Out,
              //   request_OT: self.totalvalue.all_OT,
              //   request_Time_Off: self.totalvalue.all_TO,
              // });
              // for (let k = 0; k < self.ReportList.length; k++) {
              //   self.ReportListExport.push({
              //     company_Name: self.ReportList[k].company_Name,
              //     register_Date: self.datestyle(self.ReportList[k].register_Date),
              //     expired_Date: self.datestyle(self.ReportList[k].expired_Date),
              //     total_Staff: self.ReportList[k].total_Staff,
              //     active_Staff: self.ReportList[k].active_Staff,
              //     last_Login: self.datestyle(self.ReportList[k].last_Login),
              //     login_Web: self.ReportList[k].login_Web,
              //     added_Employee: self.ReportList[k].added_Employee,
              //     post_Announcement: self.ReportList[k].post_Announcement,
              //     clock_In: self.ReportList[k].clock_In,
              //     clock_Out: self.ReportList[k].clock_Out,
              //     request_OT: self.ReportList[k].request_OT,
              //     request_Time_Off: self.ReportList[k].request_Time_Off,
              //   });
              // }
            }
            self.loadingdialog = false;
          }).catch(function(error)
          {
            alert(error);
            self.LogTrace(error, "Get Usage Report Fail", 41, "Critical");
            self.loadingdialog = false;
          })
        }
      } catch (error) {
        alert("error");
        self.loadingdialog = false;
      }
    },

    onChangecustomDDL(value) {
      let self = this;
      self.disablepicker = true;
      var today = new Date();
      if (value != null) 
      {
        switch (value) {
          case 1:
            self.reportDateFrom.setDate(today.getDate());
            self.reportDateTo.setDate(today.getDate());
            break;
          case 2:
            self.reportDateFrom.setDate(today.getDate() - 1);
            self.reportDateTo.setDate(today.getDate() - 1);
            break;
        }
      } 
      else 
      {
        self.disablepicker = false;
        self.reportDateFrom = new Date();
        self.reportDateTo = new Date();
      }
      self.replength = 0;
      self.GetReportList();
    },

    Export() {
      let self = this;
      self.loadingdialog = true;
      let tempsheetname = "";
      let wb = XLSX.utils.book_new();
      try {
        if (self.reportDateFrom != null) 
        {
          let month = String(self.reportDateFrom.getMonth() + 1);
          let day = String(self.reportDateFrom.getDate());
          const year = String(self.reportDateFrom.getFullYear());
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;
          self.startyear = `${day}/${month}/${year}`;
          let emonth = String(self.reportDateTo.getMonth() + 1);
          let eday = String(self.reportDateTo.getDate());
          const eyear = String(self.reportDateTo.getFullYear());
          if (emonth.length < 2) emonth = "0" + emonth;
          if (eday.length < 2) eday = "0" + eday;
          self.endyear = `${eday}/${emonth}/${eyear}`;
        }
        let tof = self.AItabs;
        let pa = 1;
        let perpa = self.totalItems;
        let url = `${self.url}FeatureReport/GetReportByType?status=${tof}&page=${pa}&perpage=${perpa}`;
          if (self.startyear != null) 
          {
            url += `&startDate=${self.startyear}`;
          }
          if (self.endyear != null) 
          {
            url += `&endDate=${self.endyear}`;
          }
          if (self.search != "") 
          {
            url += `&companyName=${self.search}`;
          }
          axios.get(url).then(function (response) {
            
            let expoList = response.data.data.result;
            self.replength = self.totalItems;
            if (expoList[0]) 
            {
              self.tmpLog = response.data.data;
              self.totalvalue.all_Staff = expoList[0].all_Staff;
              self.totalvalue.all_Active_Staff = expoList[0].all_Active_Staff;

              for (let j = 0; j < expoList.length; j++) 
              {
                self.totalvalue.all_login = self.totalvalue.all_login + parseInt(expoList[j].login_Web);
                self.totalvalue.all_addEmp = self.totalvalue.all_addEmp + parseInt(expoList[j].added_Employee);
                self.totalvalue.all_announcement = self.totalvalue.all_announcement + parseInt(expoList[j].post_Announcement);
                self.totalvalue.all_In = self.totalvalue.all_In + parseInt(expoList[j].clock_In);
                self.totalvalue.all_Out = self.totalvalue.all_Out + parseInt(expoList[j].clock_Out);
                self.totalvalue.all_OT = self.totalvalue.all_OT + parseInt(expoList[j].request_OT);
                self.totalvalue.all_TO = self.totalvalue.all_TO + parseInt(expoList[j].request_Time_Off);
              }
              self.ReportListExport.push({
                company_Name: "Total",
                register_Date: " ",
                expired_Date: " ",
                total_Staff: self.totalvalue.all_Staff,
                active_Staff: self.totalvalue.all_Active_Staff,
                last_Login: " ",
                login_Web: self.totalvalue.all_login,
                added_Employee: self.totalvalue.added_Employee,
                post_Announcement: self.totalvalue.all_announcement,
                clock_In: self.totalvalue.all_In,
                clock_Out: self.totalvalue.all_Out,
                request_OT: self.totalvalue.all_OT,
                request_Time_Off: self.totalvalue.all_TO,
              });
              for (let k = 0; k < expoList.length; k++) {
                self.ReportListExport.push({
                  company_Name: expoList[k].company_Name,
                  register_Date: self.datestyle(expoList[k].register_Date),
                  expired_Date: self.datestyle(expoList[k].expired_Date),
                  total_Staff: expoList[k].total_Staff,
                  active_Staff: expoList[k].active_Staff,
                  last_Login: self.datestyle(expoList[k].last_Login),
                  login_Web: expoList[k].login_Web,
                  added_Employee: expoList[k].added_Employee,
                  post_Announcement: expoList[k].post_Announcement,
                  clock_In: expoList[k].clock_In,
                  clock_Out: expoList[k].clock_Out,
                  request_OT: expoList[k].request_OT,
                  request_Time_Off: expoList[k].request_Time_Off,
                });
              }
            }
            let wsdata = [];
            for (let v of self.ReportListExport) {
              wsdata.push({
                ["Company Name"]: v.company_Name,
                ["Register Date"]: v.register_Date,
                ["Expiry Date"]: v.expired_Date,
                ["Total Staff"]: v.total_Staff,
                ["Active Staff"]: v.active_Staff,
                ["Last Login"]: v.last_Login,
                ["Login On Web"]: v.login_Web,
                ["Add Employee"]: v.added_Employee,
                ["Post Announcement"]: v.post_Announcement,
                ["Clock In"]: v.clock_In,
                ["Clock Out"]: v.clock_Out,
                ["Request OT"]: v.request_OT,
                ["Request Time Off"]: v.request_Time_Off,
              });
            }
            const ws = XLSX.utils.json_to_sheet(wsdata);

            if (self.AItabs) 
            {
              tempsheetname = `OptimisticUsageReportByType_Active_${self.startyear}_${self.endyear}`;
            } 
            else 
            {
              tempsheetname = `OptimisticUsageReportByType_InActive_${self.startyear}_${self.endyear}`;
            }

            var tempStartyear = self.startyear.split("/");
            self.sheetTitle = `${tempStartyear[0]}-${tempStartyear[1]}-${tempStartyear[2]}`;
            XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
            self.LogTrace(null, "Export Usage Report", 41, "Verbose");
            XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
              compression: true,
            });
            self.LogTrace(null, "Export Usage Report", 41, "Verbose");
            self.loadingdialog = false;
            self.exportdialog = false;
          }).catch(function(error)
          {
            alert(error);
            //self.LogTrace(error, "Get Usage Report Fail", 41, "Critical");
            self.loadingdialog = false;
            self.exportdialog = false;
          })
        
      } 
      catch (error) 
      {
        alert("error");
        self.LogTrace(error, "Export Usage Report Fail", 41, "Verbose");
        self.loadingdialog = false;
        self.exportdialog = false;
      }
    },

    sortFun(rowName) {
      let self = this;
      let keyName = [
        "company_Name",
        "register_Date",
        "expired_date",
        "total_Staff",
        "active_Staff",
        "last_Login",
        "login_Web",
        "added_Employee",
        "post_Announcement",
        "clock_In",
        "clock_Out",
        "request_OT",
        "request_Time_Off",
      ];
      if (rowName == "company_Name" || rowName == "register_Date")
        {
        self.ReportList = self.ReportList.sort((a, b) =>
          self[rowName] ? b[rowName].localeCompare(a[rowName]) : a[rowName].localeCompare(b[rowName]));
      }
      else
      {
        self.ReportList = self.ReportList.sort((a, b) => self[rowName] ? b[rowName] - a[rowName] : a[rowName] - b[rowName]);
      }

      for (let x of keyName) 
      {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
      self.page = 1;
    },

    datestyle(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    Search() {
      let self = this;
      if (self.search != "" && self.search != undefined) 
      {
        self.searchlength = 1;
        self.page = 1;
        self.GetReportList();
        // let List = self.ReportList;
        // self.ReportList = [];
        // self.ReportListExport = [];
        // self.totalvalue.all_Staff = 0;
        // self.totalvalue.all_Active_Staff = 0;
        // self.totalvalue.all_login = 0;
        // self.totalvalue.all_announcement = 0;
        // self.totalvalue.all_In = 0;
        // self.totalvalue.all_Out = 0;
        // self.totalvalue.all_OT = 0;
        // self.totalvalue.all_TO = 0;
        // self.totalvalue.all_addEmp = 0;
        // let Stxt = self.search.toLowerCase();
        // for (let i = 0; i < List.length; i++) 
        // {
        //   if (List[i].company_Name.toLowerCase().includes(Stxt)) {
        //     self.ReportList.push(List[i]);
        //     self.totalvalue.all_Staff = self.totalvalue.all_Staff + parseInt(List[i].total_Staff);
        //     self.totalvalue.all_Active_Staff = self.totalvalue.all_Active_Staff + parseInt(List[i].active_Staff);
        //     self.totalvalue.all_login = self.totalvalue.all_login + parseInt(List[i].login_Web);
        //     self.totalvalue.all_addEmp = self.totalvalue.all_addEmp + parseInt(List[i].added_Employee);
        //     self.totalvalue.all_announcement = self.totalvalue.all_announcement + parseInt(List[i].post_Announcement);
        //     self.totalvalue.all_In = self.totalvalue.all_In + parseInt(List[i].clock_In);
        //     self.totalvalue.all_Out = self.totalvalue.all_Out + parseInt(List[i].clock_Out);
        //     self.totalvalue.all_OT = self.totalvalue.all_OT + parseInt(List[i].request_OT);
        //     self.totalvalue.all_TO = self.totalvalue.all_TO + parseInt(List[i].request_Time_Off);
        //   }
        // }
        // self.ReportListExport.push({
        //   company_Name: "Total",
        //   register_Date: " ",
        //   expired_Date: " ",
        //   total_Staff: self.totalvalue.all_Staff,
        //   active_Staff: self.totalvalue.all_Active_Staff,
        //   last_Login: " ",
        //   login_Web: self.totalvalue.all_login,
        //   added_Employee: self.totalvalue.added_Employee,
        //   post_Announcement: self.totalvalue.all_announcement,
        //   clock_In: self.totalvalue.all_In,
        //   clock_Out: self.totalvalue.all_Out,
        //   request_OT: self.totalvalue.all_OT,
        //   request_Time_Off: self.totalvalue.all_TO,
        // });

        // for (let k = 0; k < self.ReportList.length; k++) 
        // {
        //   self.ReportListExport.push({
        //     company_Name: self.ReportList[k].company_Name,
        //     register_Date: self.datestyle(self.ReportList[k].register_Date),
        //     expired_Date: self.datestyle(self.ReportList[k].expired_Date),
        //     total_Staff: self.ReportList[k].total_Staff,
        //     active_Staff: self.ReportList[k].active_Staff,
        //     last_Login: self.datestyle(self.ReportList[k].last_Login),
        //     login_Web: self.ReportList[k].login_Web,
        //     added_Employee: self.ReportList[k].added_Employee,
        //     post_Announcement: self.ReportList[k].post_Announcement,
        //     clock_In: self.ReportList[k].clock_In,
        //     clock_Out: self.ReportList[k].clock_Out,
        //     request_OT: self.ReportList[k].request_OT,
        //     request_Time_Off: self.ReportList[k].request_Time_Off,
        //   });
        // }
      } else {
        alert("Please enter anything!!");
        // self.GetUserGroup();
      }
    },
  },
};
</script>
<style scoped>
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d0d0d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.dcard {
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px !important;
}

::v-deep.mx-calendar-icon {
  display: none !important;
}

::v-deep.mx-input {
  border: 2px solid #f99d20 !important;
}

.txt16 {
  font-weight: 500;
  font-size: 16px;
}

.txt14 {
  font-weight: normal;
  font-size: 14px;
}

.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}

::v-deep.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

::v-deep.theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep.theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

::v-deep.v-data-table tr td {
  height: 70px;
}

::v-deep.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

::v-deep.theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}

::v-deep.theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 280px) {
  .export {
    position: absolute;
    margin-left: auto;
    margin-top: 5rem;
  }
}
</style>