import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import CountryFlag from "vue-country-flag";
import i18n from "@/translate/i18n";
import FlagIcon from "vue-flag-icon";
import store from "./store";
import firebase from "firebase/app";
import "@firebase/messaging";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from "axios";

Vue.config.productionTip = false;
Vue.component("country-flag", CountryFlag);
Vue.component('date-picker', DatePicker);
Vue.use(FlagIcon);

const token = localStorage.getItem("jwt_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
  response => {
    // Return the response if it's successful
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      router.push({ name: 'Login' }).catch(() => { });
    }
    return Promise.reject(error);
  }
);
var firebaseConfig = {
  apiKey: "AIzaSyAI4HkLNx1R3atL7BxB7qwvEKQqjiY8k4Y",
  authDomain: "optimistic-cca96.firebaseapp.com",
  databaseURL: "https://optimistic-cca96.firebaseio.com",
  projectId: "optimistic-cca96",
  storageBucket: "optimistic-cca96.appspot.com",
  messagingSenderId: "116479546535",
  appId: "1:116479546535:web:dc597974122619205eb782",
  measurementId: "G-DWP25MZ4LD",
};
if (firebase.messaging.isSupported()) {
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
  }

  Vue.prototype.$messaging = firebase.messaging();

  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      Vue.prototype.$messaging.useServiceWorker(registration);
    })
    .catch((err) => {
      console.log(err);
      // console.log('reach to catch')
    });
}
new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
